<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Make a Withdrawal</p>
        <v-row>
          <v-col cols="10" class="py-0 my-1">
            <v-text-field
              v-model="userName"
              outlined dense
              label="Search username, phone number, unique code..."
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="py-0 my-1">
            <v-btn
              color="success"
              class="me-3"
              fab small
              @click="getWithrawalUsers()"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedUser"
              ref="selectedUser"
              :items="users"
              item-text="username"
              item-value="id"
              outlined dense
              label="Select a user"
              @change="getUserDetails()"
            ></v-select>
          </v-col>

          <v-col cols="12" class="py-0 my-1">
            <v-text-field
              v-model="client_amount_saved"
              outlined dense disabled
              label="Client's Amount Saved"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedWithdrawals"
              ref="selectedWithdrawals"
              :items="client_withdrawals"
              item-text="info"
              item-value="id"
              outlined dense
              label="Select Withdrawal"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <!-- current password -->
            <v-text-field
              v-model="currentPassword"
              ref="currentPassword"
              :type="isCurrentPasswordVisible ? 'text' : 'password'"
              :append-icon="isCurrentPasswordVisible ? mdiEyeOffOutline:mdiEyeOutline"
              label="Input Current Password"
              outlined
              dense
              @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn
          v-else
          color="success"
          class="me-3 mt-3"
          @click="withdrawMoneyCampaign()"
        >
          Withdraw
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import Spinner from "@/components/Spinner.vue";
import axios from 'axios'

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      loading: false,
      userName: "",
      selectedUser: "",
      phoneNumber: "",
      selectedCampaign: "",
      isCurrentPasswordVisible: false,
      currentPassword: "",
      amount: "",
      client_amount_saved: "",
      client_withdrawals: [],
      selectedWithdrawals: "",

      users: [],
      campaigns: [],
      mdiKeyOutline,
      mdiLockOpenOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
    }
  },
  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Savings | Withdraw from Campaign"
  },

  methods:{
    getWithrawalUsers(){
      axios
        .get('/api/v1/topup/get/clients/withdrawals/'+this.userName+'/')
        .then(response => {
          this.users = response.data  // get the data and fill into campaigns
          console.log(this.users)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    getUserDetails(){
      axios
        .get('/api/v1/topup/get/client/withdrawal/information/'+this.selectedUser+'/')
        .then(response => {
          console.log(response.data)
          this.client_amount_saved = response.data["amount_saved"]
          this.client_withdrawals = response.data['client_withdrawals']  // get the data and fill into campaigns
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async withdrawMoneyCampaign(){
        if(this.currentPassword === ""){
            this.$store.commit('setSnackBarMessage', "Please input ur password.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.currentPassword.focus()  // this causes a an auto fucos to the element
        }
        else if(this.selectedUser === ""){
            this.$store.commit('setSnackBarMessage', "Please select a user.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.selectedUser.focus()  // this causes a an auto fucos to the element
        }
        else if(this.selectedWithdrawals === ""){
            this.$store.commit('setSnackBarMessage', "Please select a withdrawal.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.selectedWithdrawals.focus()  // this causes a an auto fucos to the element
        }
        else {
            this.loading = true
  
            let formData = new FormData()
            formData.append('user_id', this.selectedUser)
            formData.append('withdrawal_id', this.selectedWithdrawals)
            formData.append('password', this.currentPassword)

            await axios
                .post('/api/v1/topup/withdrawals/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    console.log(response.data)
                    this.$store.commit('setSnackBarMessage', response.data[0].text)
                    this.$store.commit('setSnackBarColor', response.data[0].color)
                    this.$store.commit('activateSnackBar', true)
                    this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                    if (response.data[0].category === "success"){
                      var navigation = this.$router
                      setTimeout(function(){
                        navigation.go()
                      }, 3000)
                    }
                })
                .catch(error => {
                    if (error.response){
                        this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }else{
                        this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                })
            
            this.loading = false
        }

    },
  },
}
</script>
